var $ = jQuery.noConflict();
//scrolling
$('.scrollerButton').on('click', function(){
    var destSelector = $(this).attr('data-destination'),
        destOffset   = $(this).attr('data-offset'),
        destDomElement = $('.'+destSelector);
    
    $('html, body').animate({
        scrollTop: destDomElement.offset().top - destOffset}, 'slow'
    );
});


// dropdown mobile menu
$(document).ready(function(){ 
  $(".humb-icon").on('click', function() { 
    if ($('ul.menu.vertical').css('display') === 'none') {
      $('ul.menu.vertical').fadeIn('slow');
      $(this).addClass('open');
    } else {
      $('ul.menu.vertical').fadeOut('slow');
      $(this).removeClass('open');
    }
  });
});

//custom select
$(document).ready(function($) {
  $('#frmSelect').select2({
    minimumResultsForSearch: -1,
    placeholder: {
      id: '-1', // the value of the option
      text: "Scegli l'indirizzo *"
    }
  });
  
  $('#frmSelect').on('change', function (e) {
    $(this).valid();
  });
});

//carousel
$(document).ready(function(){
    var slider = $('.hpCarousel');
    slider.slick({
      autoplay: true,
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {breakpoint: 1025, settings: {slidesToShow: 2, slidesToScroll: 1, infinite: true, dots: true}},
        {breakpoint: 640, settings: {slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true}}
      ]
    });
    
    var sliderPartners = $('.sliderPartners');
    sliderPartners.slick({
      autoplay: true,
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {breakpoint: 1025, settings: {slidesToShow: 2, slidesToScroll: 1, infinite: true, dots: true}},
        {breakpoint: 640, settings: {slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true}}
      ]
    });    
});


//chiamata ajax di tipo POST per invio dati del form a mailer.php
$('form').on('submit', function (e) {
  e.preventDefault();
  var request = $.ajax({
    type: 'post',
    url: 'mailer.php',
    data: $('form').serialize(),
    dataType:'JSON'
  });
  
  request.done(function(data){
    if(data.response === 0) {
      window.location.href = 'grazie.html';
    }
  });
});

//regole della validazione del form con validate.js
$(function(){
  $.validator.addMethod("phoneL", function(value, element) {
    return this.optional(element) || /^3\d{2}\d{6,7}$/.test(value);
  }, "Inserisci il tuo numero mobile senza spazi");
  
  $('form').validate({
    highlight: function (element, errorClass, validClass) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
          $("#select2-" + elem.attr("id") + "-container").parent().addClass(errorClass); 
      } else {
          elem.addClass(errorClass);
      }
    },    
    unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
             $("#select2-" + elem.attr("id") + "-container").parent().removeClass(errorClass);
        } else {
            elem.removeClass(errorClass);
        }
    },
    errorPlacement: function(error, element) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
          element = $(".errorTxt.frmSelect"); 
          error.insertAfter(element);
      } else if(elem.attr('id') === "frmPrivacy") {
          element = $(".errorTxt.frmPrivacy"); 
          error.insertAfter(element);
      } else {
          error.insertAfter(element);
      }
    },
    rules: {
      frmNome: {
        required: true,
        minlength: 4     
      },
      frmEmail: {
        required: true,
        email: true
      },
      frmTel: {
        required: true,
        phoneL: true
      },
      frmSelect: { 
        required: true
      },
      frmPrivacy: { 
        required: true
      }
    },
    messages: {
      frmNome: {
        required: "Questo campo è obbligatorio",
        minlength: "Il campo deve essere di minimo 4 caratteri"
        
      },
      frmEmail: {
        required: "Questo campo è obbligatorio",
        email: "Per favore inserisci una mail valida"
      },
      frmTel: {
        required: "Questo campo è obbligatorio",
        digits: "Per favore inserisci il tuo numero"
      },
      frmSelect: { required: "Questo campo è obbligatorio" },
      frmPrivacy: { required: "Questo campo è obbligatorio" }
    }
  });
});

//lancio grecaptcha.execute() all'azione submit
grecaptcha.ready(function() {
  grecaptcha.execute('6LecaqgZAAAAAPZx-NewqA11sIKmIq4zr8cVyzEB', {action: 'submit'}).then(function(token) {
    var response = document.querySelector('#token-response');
    response.value = token;
  });
});

